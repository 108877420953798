.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.pickup-header-section header {
  background-color: #FE588A !important;
}

.logoAnimation path {
  fill: red;
}

.logoAnimation .logo-Animation {
  fill: white;
}

.pickup-header-section .MuiBox-root {
  justify-content: flex-end;
  padding-right: 2rem;
  position: relative;
}

.pickup-header-section .MuiBox-root button {
  text-transform: capitalize;
  /* font-family:'Roboto' */
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.pickup-middel-section{
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.previous-results{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;
}

.pickuplines-for-gender{
  text-align: left;
  padding-left: 1rem;
  flex-grow: 1;
  flex-basis: 500px;
}
.pickuplines-for-gender h2{
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  color: #FE588A;
}
.pickuplines-for-gender ul{
  list-style: none;
  padding-left: 0.5rem;
}
.pickuplines-for-gender ul li,p{
  font-size: 18px;
  font-family: Lato;
  font-weight: 500;
  color: white;
  margin: 1rem;
}
.pickuplines-for-gender p{ 
  line-height: 35px;
  background-color: transparent;
  color: black;
}
.root-conatiner {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("./Images/pickupbackground.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
}

.root-conatiner::-webkit-scrollbar {
  display: none;
}

.footerContainer {
  width: 100%;
  height: auto;
  padding: 1rem;
}

.fotter-content {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1vw;
  flex-wrap: wrap;
}

.fotter-content a {
  text-decoration: none;
  color: #FE588A;
}


         
.btn-grad {
  background-image: linear-gradient(to right, #ff6e7f 0%, black  51%, #ff6e7f  100%);
  margin: 10px;
  padding: 20px 45px !important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white !important;  
  font-family: Roboto !important;
  font-size: 35px !important;
  font-weight: 600 !important; 
  text-transform: capitalize !important;  
  box-shadow: 0 0 20px #eee !important;
  border-radius: 12px !important;
  display: block;
  transition: 0.9s linear;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  transition: 0.9s linear;

}
@media (max-width:430px) {
  .fotter-content{ 
    flex-direction: column;
    row-gap: 2rem;
  }
  .btn-grad{
    font-size: 30px !important;
  }
}